
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        




































































































































.wysiwyg-nav-outer {
  @extend %box-shadow;

  position: relative;
  margin-top: $spacing * 3;

  @include mq($until: l) {
    overflow: hidden;
    height: 5rem;
    margin-bottom: $spacing * 2;
    padding-left: $spacing;
    line-height: 5rem;
    border: 1px solid $gray-lightest;
    border-radius: 5px;
  }

  @include mq(l) {
    position: sticky;
    top: $spacing * 2.5;
    box-shadow: none;
  }

  &:not(.is-open) {
    @include mq($until: l) {
      * {
        pointer-events: none;
      }
    }
  }
}

.wysiwyg-nav__icon {
  position: absolute;
  z-index: 1;
  top: 2.5rem;
  right: $spacing;
  width: 1.5rem;
  height: 1.5rem;
  fill: $c-pink-medium;
  transform: translateY(-50%);
  pointer-events: none;
  transition: transform 0.2s ease-out;

  @include mq(l) {
    display: none;
  }

  .is-open & {
    transform: rotate(180deg) translateY(50%);
  }
}

.wysiwyg-nav,
[class*='wysiwyg-nav--'] {
  @extend %list-nostyle;
}

.wysiwyg-nav__item {
  height: 5rem;
  line-height: 5rem;
}

.wysiwyg-nav__item__link {
  @extend %fw-bold;

  font-family: $ff-alt;
  font-size: 1.5rem;
  text-decoration: none;
  transition: color 0.2s ease-out;

  &:hover {
    color: $c-pink-medium;
  }

  &.router-link-active {
    color: $c-pink-medium;
  }
}
